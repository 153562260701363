import { getCrmLanguage } from "./utils"

export const LIMIT = 20
export const PLACEHOLDER_IMAGE = "https://www.immobiliarepizzigalli.com/wp-content/uploads/2017/01/banner-placeholder.jpg"
export const HEIGHT_SCROLL = "100%"
export const MAX_CM_VANO_DOCCIA = 999

export const ID_TUTTOVETRO_CONTOUR_PORTA = "porta-tuttovetro-contour"
export const ID_TUTTOVETRO_CONTOUR_VETRATA = "vetrata-tuttovetro-contour"
export const SLUG_TUTTOVETRO_CONTOUR = "tuttovetro-contour"
export const CODE_TUTTOVETRO_CONTOUR = "HA60059996"

export const ID_TUTTOVETRO_INSIDE_PORTA = "porta-tuttovetro-inside"
export const ID_TUTTOVETRO_INSIDE_VETRATA = "vetrata-tuttovetro-inside"
export const SLUG_TUTTOVETRO_INSIDE = "tuttovetro-inside"
export const CODE_TUTTOVETRO_INSIDE = "HA60059997"
export const CODE_TELAIO_TUTTOVETRO_INSIDE = "HA85409995"

export const SLUG_ADAPTA_CUSTOM = "adapta-2"
export const ID_ADAPTA_PORTA = "porta-adapta"
export const CODE_ADAPTA = "ADAPTA" 

//Materials
export const SLUG_MATERIALE_DEFAULT = "alluminio-finitura-brillantata"
export const SLUG_MATERIALE_SPAZZOLATA = "alluminio-finitura-spazzolata"
export const SLUG_MATERIALE_ANODIZZATO = "alluminio-finitura-anodizzata-nero-opaco"
export const SLUG_MATERIALE_BRONZO_SATINATO = "bronzo-satinato"
export const SLUG_MATERIALE_NERO_SATINATO = "nero-satinato"
export const SLUG_MATERIALE_RAME_SATINATO = "rame-satinato"

export const SLUG_ANGOLO_VETRO_PORTA_CONTOUR = "angolo-vetro-porta-tuttovetro-contour"
export const SLUG_ANGOLO_VETRO_PORTA_INSIDE = "porta-ad-angolo-tuttovetro-inside"

//porta
export const MIN_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM = 100
export const MAX_DIM_ALTEZZA_PORTA_VETRATA_CUSTOM = 250
export const MIN_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM = 60
export const MAX_DIM_LARGHEZZA_PORTA_VETRATA_CUSTOM = 350
//vetrata
export const MIN_DIM_LARGHEZZA_VETRATA_CUSTOM = 20

export const SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE = "panca-custom-sezione-rettangolare"
export const SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED = "panca-custom-sezione-rettangolare-led"
export const SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED = "panca-custom-sezione-arrotondata"
export const SLUG_KIT_COIBENTAZIONE_15_21_MQ = "ha-55-03-0002-kit-coibentazione-fino-a-21-mq"
export const SLUG_KIT_COIBENTAZIONE_12_15_MQ = "ha-55-02-0002-kit-coibentazione-fino-a-15-mq"
export const SLUG_KIT_COIBENTAZIONE_0_12_MQ = "ha-55-01-0004-kit-coibentazione-fino-a-12-mq"

//Rettangolare
export const SLUG_PANCA_CONFIGURATION_RETTANGOLARE_BASE = "SV31000008"
export const SLUG_PANCA_CONFIGURATION_RETTANGOLARE_END_COVER = "SV31000009 - SV31000024"
export const SLUG_PANCA_CONFIGURATION_RETTANGOLARE_ANGOLO = "SV31000010"
//Rettangolare LED
export const SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_BASE = "SV31000257"
export const SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_END_COVER = "SV31000258 - SV31000259"
export const SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_ANGOLO = "SV31000260"
//Arrotondata
export const SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_BASE = "SV31000262"
export const SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_END_COVER = "SV31000263 - SV31000264"
export const SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_ANGOLO = "SV31000265"



export const SLUG_NUVOLA_SP_30 = "nuvola-smart-power-30-2"
export const SLUG_NUVOLA_SP_45 = "nuvola-smart-power-45-2"
export const SLUG_NUVOLA_SP_55 = "nuvola-smart-power-55-2"
export const SLUG_NUVOLA_SP_70 = "nuvola-smart-power-70-2"
export const SLUG_NUVOLA_SP_90 = "nuvola-smart-power-90"
export const SLUG_NUVOLA_SP_110 = "nuvola-smart-power-110"
export const SLUG_NUVOLA_SP_140 = "nuvola-smart-power-140"
export const SLUG_NUVOLA_SP_180 = "nuvola-smart-power-180"
export const SLUG_NUVOLA_SP_250 = "nuvola-smart-power-250"
export const SLUG_NUVOLA_SP_360 = "nuvola-smart-power-360"

export const SLUG_PRODOTTO_SPAZIO = "spazio"
export const SLUG_PRODOTTO_SPAZIOFILO = "spaziofilo"

export const SLUG_OPTIONAL_STEAM_DIFFUSER_FINISHES = "steam-diffusers-finishes"

export const getLanguageForNSP = () => {
    let crmLanguage = (getCrmLanguage() ?? "in")?.slice(0,2)?.toUpperCase();
    let language = crmLanguage;
    if(!["IT","IN","TE","FR","SP"].includes(crmLanguage)){
        switch (crmLanguage) {
            case "EN":
                language = "IN"
                break;
            case "ES":
                language = "SP"
                break;
            case "DE":
                language = "TE"
                break;
            default:
                language = "IN";
                break;
        }
    }

    return language;
}
export const OPTIONALS_SMART_POWER:{[key: string]: {
    GLASS: {[key: string]:string};
    CAMOUFLAGE: {[key: string]:string};
    TRADITIONAL_CROMO: {[key: string]:string};
    TRADITIONAL_NERO: {[key: string]:string};
    TRADITIONAL_GUN: {[key: string]:string};
  }} = {
    "1PZ":{
        GLASS: {
          "IT": "32550",
          "IN": "33684",
		  "FR": "33685",
		  "TE": "33686",
		  "SP": "33687",
        },
        CAMOUFLAGE: {
			"IT": "32551",
			"IN": "33680",
			"FR": "33681",
			"TE": "33682",
			"SP": "33683",
     
        },
        TRADITIONAL_CROMO: {
			"IT": "32552",
			"IN": "33676",
			"FR": "33677",
			"TE": "33678",
			"SP": "33679",
     
        },
        TRADITIONAL_NERO: {
			"IT": "32584",
			"IN": "33445",
			"FR": "33673",
			"TE": "33674",
			"SP": "33675",
     
        },
        TRADITIONAL_GUN: {
			"IT": "32588",
			"IN": "33688",
			"FR": "33689",
			"TE": "33690",
			"SP": "33691",
     
        },
    },
    "2PZ":{
        GLASS: {
			"IT": "32581",
			"IN": "33641",
			"FR": "33642",
			"TE": "33643",
			"SP": "33644",
     
        },
        CAMOUFLAGE: {
			"IT": "32575",
			"IN": "33645",
			"FR": "33646",
			"TE": "33647",
			"SP": "33648",
     
        },
        TRADITIONAL_CROMO: {
			"IT": "32572",
			"IN": "33657",
			"FR": "33658",
			"TE": "33659",
			"SP": "33660",
     
        },
        TRADITIONAL_NERO: {
			"IT": "32585",
			"IN": "33629",
			"FR": "33630",
			"TE": "33631",
			"SP": "33632",
     
        },
        TRADITIONAL_GUN: {
			"IT": "32589",
			"IN": "33617",
			"FR": "33618",
			"TE": "33619",
			"SP": "33620",
     
        }
    },
    "4PZ":{
        GLASS: {
			"IT": "32582",
			"IN": "33637",
			"FR": "33638",
			"TE": "33639",
			"SP": "33640",
     
        },
        CAMOUFLAGE: {
			"IT": "32592",
			"IN": "33591",
			"FR": "33592",
			"TE": "33593",
			"SP": "33594",
     
        },
        TRADITIONAL_CROMO: {
			"IT": "32573",
			"IN": "33653",
			"FR": "33654",
			"TE": "33655",
			"SP": "33656",

        },
        TRADITIONAL_NERO: {
			"IT": "32586",
			"IN": "33625",
			"FR": "33626",
			"TE": "33627",
			"SP": "33628",
     
        },
        TRADITIONAL_GUN: {
			"IT": "32590",
			"IN": "33613",
			"FR": "33614",
			"TE": "33615",
			"SP": "33616",
     
        }
    },
    "8PZ":{
        GLASS: {
			"IT": "32583",
			"IN": "32583",
			"FR": "32583",
			"TE": "32583",
			"SP": "32583",
        },
        CAMOUFLAGE: {
			"IT": "32593",
			"IN": "32593",
			"FR": "32593",
			"TE": "32593",
			"SP": "32593",
        },
        TRADITIONAL_CROMO: {
			"IT": "32574",
			"IN": "33649",
			"FR": "33650",
			"TE": "33651",
			"SP": "33652",
        },
        TRADITIONAL_NERO: {
			"IT": "32587",
			"IN": "33621",
			"FR": "33622",
			"TE": "33623",
			"SP": "33624",

        },
        TRADITIONAL_GUN: {
			"IT": "32591",
			"IN": "33595",
			"FR": "33596",
			"TE": "33597",
			"SP": "33598",

        }
    },
}
export const OPTIONALS_PANNELLO_CONTROLLO:{
    INTERNO_OPTIONAL:{[key: string]:string},
    INTERNO:{[key: string]:string},
    ESTERNO:{[key: string]:string}
} = {
    INTERNO_OPTIONAL: {
			"IT": "2879",
			"IN": "20424",
			"FR": "20425",
			"TE": "20426",
			"SP": "20427",
     
        }, //Hanno duplicato l'interno: uno è relativo al generatore ed uno è un optional
    INTERNO: {
			"IT": "2881",
			"IN": "20416",
			"FR": "20417",
			"TE": "20418",
			"SP": "20419",
     
        }, //https://www.effe.it/wp-admin/post.php?post=2881&action=edit&lang=it
    ESTERNO: {
			"IT": "2880",
			"IN": "20420",
			"FR": "20421",
			"TE": "20422",
			"SP": "20423",
     
        },
}

export const IMAGES_VANO = {
    STEP_1: {
        DIMENSIONI: {
            DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B1.0%5D+dimensioni.png",
            MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/1+-+Dimensioni+-+mobile.png"
        },
        VANO_INTERNO: {
            ESTERNO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Vano/Vano+esterno+-+Desktop.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Vano/Vano+esterno+-+Mobile.png"
            },
            INTERNO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Vano/Vano+Interno+-+Desktop.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Vano/Vano+interno+-+Mobile.png"
            },
            INTERNO_MARMO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Vano/Vano+Interno+-+Marmo+-+Desktop.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Vano/Vano+Interno+-+Marmo+-+Mobile.png"
            },
            INTERNO_MOSAICO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Vano/Vano+Interno+-+Mosaico+-+Desktop.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Vano/Vano+Interno+-+Mosaico+-+Mobile.png"
            },
            INTERNO_STONE: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Vano/Vano+Interno+-+Stone+-+Desktop.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Vano/Vano+Interno+-+Stone+-+Mobile.png"
            }
        }
    },
    STEP_2: {
        STRUTTURA: {
            CARTONGESSO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.1%5D+cartongesso.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Cartongesso+-+mobile.png"
            },
            MURATURA: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.1%5D+muratura.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Muratura+-+mobile.png"
            },
            POLIESTIRENE: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.1%5D+polistirene.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Polistirene+-+mobile.png"
            },
        },
        RIVESTIMENTO: {
            MOSAICO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.2%5D+mosaico.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Mosaico+-+mobile.png"
            },
            MARMO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.2%5D+marmo.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Marmo+-+mobile.png"
            },
            PIETRA: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.2%5D+Stone.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Stone+-+mobile.png"
            },
        },
        KIT_COIBENTAZIONE: {
            MOSAICO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.3%5D+mosaico+coibentato.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Coibentato+Mosaico+-+mobile.png"
            },
            MARMO: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.3%5D+marmo+coibentato.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Coibentato+Marmo+-+mobile.png"
            },
            PIETRA: {
                DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B2.3%5D+stone+coibentato.png",
                MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/2+-+Coibentato+Stone+-+mobile.png"
            },
        },
    },
    STEP_3: {
        VETRATA_1: {
            KIT_COIBENTAZIONE: {
                MARMO: {
                    DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+marmo+coibentato.png",
                    MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+singola+Coib+Marmo+-+mobile.png"
                },
                MOSAICO: {
                    DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+mosaico+coibentato.png",
                    MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+singola+Coib+Mosaico+-+mobile.png"
                },
                PIETRA: {
                    DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+Stone+coibentato.png",
                    MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+singola+Coib+Stone+-+mobile.png"
                }
            },
            NO_KIT_COIBENTAZIONE: {
                MARMO: {
                    DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+marmo+1+vetrata.png",
                    MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+singola+Marmo+-+mobile.png"
                },
                MOSAICO: {
                    DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+mosaico+1+vetrata.png",
                    MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+singola+Mosaico+-+mobile.png"
                },
                PIETRA: {
                    DESKTOP: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+Stone+1+vetrata.png",
                    MOBILE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+singola+Stone+-+mobile.png"
                }
            }
        },
        VETRATA_2: {
            KIT_COIBENTAZIONE: {
                MARMO: {
                    DESKTOP: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+marmo+2+vetrate+coibentato.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+marmo+2+vetrate+coibentato+Mono.png"
                    },
                    MOBILE: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+doppia+Coib+Marmo+-+mobile.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/%5B3.1%5D+marmo+2+vetrate+coibentato+-+Mobile+Mono.png"
                    }
                },
                MOSAICO: {
                    DESKTOP: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+mosaico+2+vetrate+coibentato.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+mosaico+2+vetrate+coibentato+Mono.png"
                    },
                    MOBILE: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+doppia+Coib+Mosaico+-+mobile.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/%5B3.1%5D+mosaico+2+vetrate+coibentato+-+Mobile+Mono.png"
                    }
                },
                PIETRA: {
                    DESKTOP: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+Stone+2+vetrate+coibentato.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+Stone+2+vetrate+coibentato+-+Mono.png"
                    },
                    MOBILE: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+doppia+Coib+Stone+-+mobile.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/%5B3.1%5D+Stone+2+vetrate+coibentato+-+Mobile+Mono.png"
                    }
                }
            },
            NO_KIT_COIBENTAZIONE: {
                MARMO: {
                    DESKTOP: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+marmo+2+vetrate.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/3+-+Marmo+2+vetrate+-+Mono.png"
                    },
                    MOBILE: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+doppia+Marmo+-+mobile.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Marmo+2+vetrate+-+Mobile+Mono.png"
                    }
                },
                MOSAICO: {
                    DESKTOP: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+mosaico+2+vetrate.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/3+-+Mosaico+2+vetrate+-+Mono.png"
                    },
                    MOBILE: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+doppia+Mosaico+-+mobile.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Mosaico+2+vetrate+-+Mobile+Mono.png"
                    }
                },
                PIETRA: {
                    DESKTOP: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/%5B3.1%5D+Stone+2+vetrate.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/3+-+Stone+2+vetrate+-+Mono.png"
                    },
                    MOBILE: {
                        ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Vetrata+doppia+Stone+-+mobile.png",
                        DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/3+-+Stone+2+vetrate+-+Mobile+Mono.png"
                    }
                }
            }
        },
    },
    STEP_GENERATORE: {
        GENERATORE_INTERNO: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Generatore+interno+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Generatore+interno+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Generatore+interno+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Generatore+interno+-+Mobile+Mono.png"
            }
        },
        GENERATORE_ESTERNO: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Generatore+esterno+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Generatore+esterno+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Generatore+esterno+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Generatore+esterno+-+Mobile+Mono.png"
            }
        }
    },
    STEP_GENERATORE_MODELLO: {
        AQUASTEAM: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Aquasteam+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Aquasteam+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Aquasteam+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Aquasteam+-+Mobile+Mono.png"
            }
        },
        EASYSTEAM: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Easysteam+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Easysteam+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Easysteam+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Easysteam+-+Mobile+Mono.png"
            }
        },
        NUVOLA_INT: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+-+Desktop+Interno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+-+Desktop+Interno+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+-+Mobile+Interno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+-+Mobile+Interno+Mono.png"
            }
        },
        NUVOLA_EXT: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+-+Desktop+Esterno+.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+-+Desktop+Esterno+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+-+Mobile+Esterno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+-+Mobile+Esterno+Mono.png"
            }
        },
        NUVOLA_SP_INT: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+SP+-+Desktop+Interno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+SP+-+Desktop+Interno+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+SP+-+Mobile+Interno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+SP+-+Mobile+Interno+Mono.png"
            }
        },
        NUVOLA_SP_EXT: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+SP+-+Desktop+Esterno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Nuvola+SP+-+Desktop+Esterno+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+SP+-+Mobile+Esterno.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Nuvola+SP+-+Mobile+Esterno+Mono.png"
            }
        },
        TOUCH_STEAM: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Touch%26Steam+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Touch%26Steam+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Touch%26Steam+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Touch%26Steam+-+Mobile+Mono.png"
            }
        },
        INSIDE: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Inside+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Generatore/4+-+Inside+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Inside+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Generatore/4+-+Inside+-+Mobile+Mono.png"
            }
        },
    },
    STEP_OPTIONAL: {
        SOUND_SYSTEM: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Effe+sound+system+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Effe+sound+system+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Effe+sound+system+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Effe+sound+system+-+Mobile+Mono.png"
            }
        },
        DOMOTICA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Intefaccia+domotica+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Intefaccia+domotica+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Intefaccia+domotica+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Intefaccia+domotica+-+Mobile+Mono.png"
            }
        },
        PANNELLO_ESTERNO: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Pannello+esterno+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Pannello+esterno+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Pannello+esterno+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Pannello+esterno+-+Mobile+Mono.png"
            }
        },
        PANNELLO_ESTERNO_SONDA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Pannello+esterno+con+sonda+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Pannello+esterno+con+sonda+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Pannello+esterno+con+sonda+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Pannello+esterno+con+sonda+-+Mobile+Mono.png"
            }
        },
        PLAFONIERA_CROMO_BIANCA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Plafoniera+cromo+bianca+alt+passivi+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Plafoniera+cromo+bianca+alt+passivi+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Plafoniera+cromo+bianca+alt+passivi+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Plafoniera+cromo+bianca+alt+passivi+-+Mobile+Mono.png"
            }
        },
        PLAFONIERA_BIANCA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Plafoniera+ill+bianca+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Optional/5+-+Plafoniera+ill+bianca+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Plafoniera+ill+bianca+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Optional/5+-+Plafoniera+ill+bianca+-+Mobile+Mono.png"
            }
        },
    },
    STEP_PORTE: {
        TUTTOVETRO_70: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_120: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_170: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Mobile+Mono.png"
            }
        },
        FIT_120: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Fit+120+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Fit+120+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Fit+120+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Fit+120+-+Mobile+Mono.png"
            }
        },
        FIT_65_80: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Fit+65-80+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Fit+65-80+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Fit+65-80+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Fit+65-80+-+Mobile+Mono.png"
            }
        },
        FIT_V50: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Fit+vetrata+50+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Fit+vetrata+50+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Fit+vetrata+50+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Fit+vetrata+50+-+Mobile+Mono.png"
            }
        },
        SPAZIO_120_160: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spazio+120-160+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spazio+120-160+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spazio+120-160+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spazio+120-160+-+Mobile+Mono.png"
            }
        },
        SPAZIO_80: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spazio+80+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spazio+80+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spazio+80+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spazio+80+-+Mobile+Mono.png"
            }
        },
        SPAZIO_V: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spazio+vetrata+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spazio+vetrata+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spazio+vetrata+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spazio+vetrata+-+Mobile+Mono.png"
            }
        },
        SPAZIOFILO_120_160: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spaziofilo+120-160+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spaziofilo+120-160+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spaziofilo+120-160+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spaziofilo+120-160+-+Mobile+Mono.png"
            }
        },
        SPAZIOFILO_80_100: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spaziofilo+80-100+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spaziofilo+80-100+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spaziofilo+80-100+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spaziofilo+80-100+-+Mobile+Mono.png"
            }
        },
        SPAZIOFILO_V: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spaziofilo+Vetrata+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Spaziofilo+Vetrata+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spaziofilo+Vetrata+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Spaziofilo+Vetrata+-+Mobile+Mono.png"
            }
        },
        SPAZIOSLIDE_130_160: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+SpazioSlide+130-160+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+SpazioSlide+130-160+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+SpazioSlide+130-160+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+SpazioSlide+130-160+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_CONTOUR_PORTA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+A+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+A+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+A+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+A+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_CONTOUR_VETRO_PORTA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+B+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+B+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+B+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+B+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_CONTOUR_VETRO_PORTA_VETRO: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+C+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+C+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+C+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+C+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_CONTOUR_D: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+angoloA+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+angoloA+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+angoloA+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+angoloA+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_CONTOUR_V: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+Vetrata+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Contour+Vetrata+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+Vetrata+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Contour+Vetrata+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_INSIDE_A: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+70+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_INSIDE_B: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+120+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_INSIDE_C: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+170+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_INSIDE_D: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+angolo+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+angolo+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+angolo+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+angolo+-+Mobile+Mono.png"
            }
        },
        TUTTOVETRO_INSIDE_V: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+Vetrata+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/PorteVetrate/6+-+Tuttovetro+Inside+Vetrata+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+Vetrata+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/PorteVetrate/6+-+Tuttovetro+Inside+Vetrata+-+Mobile+Mono.png"
            }
        }
    },
    STEP_PANCHE: {
        SEDILE_MINI: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Sedile+Mini+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Sedile+Mini+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Sedile+Mini+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Sedile+Mini+-+Mobile+Mono.png"
            }
        },
        FONTE_MINI: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Fonte+Mini+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Fonte+Mini+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Fonte+Mini+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Fonte+Mini+-+Mobile+Mono.png"
            }
        },
        ALU_ANGOLO: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu+angolo+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu+angolo+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu+angolo+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu+angolo+-+Mobile+Mono.png"
            }
        },
        ALU_1: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu1+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu1+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu1+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu1+-+Mobile+Mono.png"
            }
        },
        ALU_2: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu2+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu2+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu2+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu2+-+Mobile+Mono.png"
            }
        },
        ALU_3: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu3+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Alu3+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu3+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Alu3+-+Mobile+Mono.png"
            }
        },
        LOGICA_114: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Logica+114+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Logica+114+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Logica+114+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Logica+114+-+Mobile+Mono.png"
            }
        },
        LOGICA_69: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Logica+69+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Logica+69+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Logica+69+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Logica+69+-+Mobile+Mono.png"
            }
        },
        LOGICA_79: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Logica+79+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Logica+79+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Logica+79+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Logica+79+-+Mobile+Mono.png"
            }
        },
        //ARROTONDATA LED
        ARROTONDATA_LED_1_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_1x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_1x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_1x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_1x0+-+Mobile+Mono.png"
            }
        },
        ARROTONDATA_LED_2_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_2x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_2x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_2x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_2x0+-+Mobile+Mono.png"
            }
        },
        ARROTONDATA_LED_2_x_1: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_2x1+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_2x1+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_2x1+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_2x1+-+Mobile+Mono.png"
            }
        },
        ARROTONDATA_LED_3_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_3x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_3x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_3x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_3x0+-+Mobile+Mono.png"
            }
        },
        ARROTONDATA_LED_3_x_2: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_3x2+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Arrotondata+LED_3x2+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_3x2+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Arrotondata+LED_3x2+-+Mobile+Mono.png"
            }
        },
        //RETTANGOLARE LED
        RETTANGOLARE_LED_1_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_1x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_1x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_1x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_1x0+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_LED_2_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_2x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_2x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_2x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_2x0+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_LED_2_x_1: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_2x1+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_2x1+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_2x1+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_2x1+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_LED_3_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_3x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_3x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_3x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_3x0+-+Mobile+Mono.png"
            }
        },

        RETTANGOLARE_LED_3_x_1: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_3x1+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_3x1+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_3x1+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_3x1+-+Mobile+Mono.png"
            }
        },

        RETTANGOLARE_LED_3_x_2: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_3x2+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/2024/7+-+Rettangolare+2024+LED_3x2+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_3x2+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/2024/7+-+Rettangolare+2024+LED_3x2+-+Mobile+Mono.png"
            }
        },
        //RETTANGOLARE
        RETTANGOLARE_1_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_1x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_1x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_1x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_1x0+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_2_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_2x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_2x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_2x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_2x0+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_2_x_1: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_2x1+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_2x1+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_2x1+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_2x1+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_3_x_0: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_3x0+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_3x0+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_3x0+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_3x0+-+Mobile+Mono.png"
            }
        },
        RETTANGOLARE_3_x_2: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_3x2+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Rettangolare_3x2+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_3x2+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Rettangolare_3x2+-+Mobile+Mono.png"
            }
        },



        TOPKAPI_115: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Topkapi+115+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Topkapi+115+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Topkapi+115+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Topkapi+115+-+Mobile+Mono.png"
            }
        },
        TOPKAPI_75: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Topkapi+75+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/Panche/7+-+Topkapi+75+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Topkapi+75+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/Panche/7+-+Topkapi+75+-+Mobile+Mono.png"
            }
        },
    },
    STEP_ACCESSORI: {
        CIOTOLA: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/accessori/8+-+Ciotola+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/accessori/8+-+Ciotola+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/accessori/8+-+Ciotola+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/accessori/8+-+Ciotola+-+Mobile+Mono.png"
            }
        },
        FIT_SHOWER: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/accessori/8+-+Fit+shower+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/accessori/8+-+Fit+shower+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/accessori/8+-+Fit+shower+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/accessori/8+-+Fit+shower+-+Mobile+Mono.png"
            }
        },
        TUBO_KNEIP: {
            DESKTOP: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/accessori/8+-+Tubo+Kneipp+-+Desktop.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/desktop/accessori/8+-+Tubo+Kneipp+-+Desktop+Mono.png"
            },
            MOBILE: {
                ACTIVE: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/accessori/8+-+Tubo+Kneipp+-+Mobile.png",
                DEFAULT: "https://s3.eu-central-1.amazonaws.com/steam.effe.it/mobile/accessori/8+-+Tubo+Kneipp+-+Mobile+Mono.png"
            }
        }
    }
}