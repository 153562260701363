import axios from "axios";
import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { useHistory } from "react-router";
import Loader, { ELoader } from "../../components/Loader";
import { API_URL, BASE_WP_URL } from "../../network";
import { actGeneral } from "../../redux/actions";
import { TGeneral } from "../../redux/reducers";
import store from "../../redux/store";
import { setAuthInfo } from "../../utils/storage";
import { hasValidRoleToLogin } from "../../utils/utils";

function Callback({...props}){
    const history = useHistory();
    const [anErrorOccurred,setAnErrorOccurred] = useState<boolean>(false);
    const [errorAuth,setErrorAuth] = useState<String>("");
    const dispatch = useDispatch()

    useEffect(()=>{
        dispatch(actGeneral({appLoading: true}))
        init()
    },[])

    const init = async () => {
        setErrorAuth("") //reset msg
        try {
            const queryString = window.location.search;
            const urlParams = new URLSearchParams(queryString);
            const code = urlParams.get('code')
            if(!code){
                setAnErrorOccurred(true)
            }else{
                const resp:any = await axios.post(`${BASE_WP_URL}/oauth/token`,{
                    grant_type:'authorization_code',
                    code: code,
                    client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
                    client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET,
                    redirect_uri: process.env.REACT_APP_AUTH_REDIRECT_URI
                })
                const respUserToken:any = await axios(`${BASE_WP_URL}/oauth/me?access_token=${resp?.data?.access_token}`)
                const respUserInfo:any = await axios(`${API_URL}/v2/users/${respUserToken?.data?.ID}?context=edit`,{
                    headers:{
                        'Authorization': `Bearer ${resp?.data?.access_token}`
                    }
                })
                const _roles = respUserInfo?.data?.extra_capabilities
                if(hasValidRoleToLogin(_roles)){
                    setAuthInfo(resp?.data, respUserToken?.data?.ID, _roles, respUserInfo?.data?.meta?.crm_language, respUserInfo?.data?.locale, respUserInfo?.data?.email)
                    history.replace("/")
                }else{
                    setErrorAuth(props.t("v2.error.ruolo_non_ammesso"))
                }                
            }
        } catch (error) {
            setAnErrorOccurred(true)
        }
    }
    return(<Loader loading={store.getState()?.general?.appLoading} type={ELoader.PRIMARY} error={anErrorOccurred || !!errorAuth} errorMsg={errorAuth}/>)
}

export default connect((state:TGeneral, ownProps) => ({
    general: state.general
  }), { actGeneral })(withTranslation()(Callback));