import { TGeneral, TGeneralStore } from "../../redux/reducers";
import { connect, useDispatch, useSelector } from "react-redux";
import Modal, { IModalType, ModalSize } from "../../components/Modal";
import { useEffect, useLayoutEffect, useState } from "react";
import Wizard from "../../components/Wizard";
import VanoDoccia from "./Steps/VanoDoccia";
import Menu from "../../components/Menu";
import { useStore } from "react-redux";
import { getCmFromFtAndInch, getLocalization, getUnitOfMeasure, hasValidRoleToLogin, isKitCoibentazioneVisible, Localization, UnitOfMeasure } from "../../utils/utils";
import { withTranslation } from "react-i18next";
import { Text } from "../../components/Typography";
import ActionButton, { IActionButtonType } from "../../components/Buttons/ActionButton";
import StrutturaVanoDoccia from "./Steps/StrutturaVanoDoccia";
import Vetrate from "./Steps/Vetrate";
import Generatore from "./Steps/Generatore";
import GeneratoreEstInt from "./Steps/GeneratoreEstInt";
import Optional from "./Steps/Optional";
import PorteVetrate from "./Steps/PorteVetrate";
import Panche from "./Steps/Panche";
import Accessori from "./Steps/Accessori";
import Voltaggio from "./Steps/Voltaggio";
import Riepilogo from "./Steps/Riepilogo";
import { API_URL, BASE_WP_URL } from "../../network";
import Loader, { ELoader } from "../../components/Loader";
import { getRefreshToken, isLogged, isTokenExpired, setAuthInfo } from "../../utils/storage";
import axios from "axios";
import { actGeneral, actGeneratoreSetData } from "../../redux/actions";
import { IMAGES_VANO, SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_ANGOLO, SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_BASE, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_ANGOLO, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_BASE, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_ANGOLO, SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_BASE, SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED, SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE, SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED } from "../../utils/consts";
import { getVolume } from "../../redux/reducers/generatoreestint";
import { EGeneratoreType } from "../../redux/reducers/generatore";
import { ERivestimentoVanoDoccia, EStrutturaVanoDoccia } from "../../redux/reducers/struttura_vano_doccia";
import { EInternalExternalImage, TGeneralStoreState } from "../../redux/reducers/general";
import ic_menu from "./../../assets/images/menu.png"
import ic_menu_white from "./../../assets/images/menu_white.png"
import logo from "./../../assets/images/Logo-Effe.svg"
import logo_white from "./../../assets/images/Logo-Effe-Bianco.svg"
import logo_us from "./../../assets/images/PerfectWellness Logo big-01.png"
import { Col } from "react-bootstrap";
import ImgVano from "../../components/ImgVano";
import SceltaConfigurazione from "./Steps/SceltaConfigurazione";
import { ConfigurationType, TSceltaConfigurazioneStoreState } from "../../redux/reducers/scelta_configurazione";

export function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export type StepErrorField = { id: string; msg: string; }
export type StepError = {
  fields: StepErrorField[];
  msg: string;
  step: StepConfiguratore;
}

export const WIDTH_DESKTOP = 1024

export type ImgType = {
  src: string;
  zIndex?: number;
}

export type TMenuItem = {
  component: any;
  label?: string;
  position: number;
  positionInMenu?: number;
  hideInMenu: boolean;
}

export enum StepConfiguratore {
  SceltaConfigurazione = 0,
  VanoDoccia = 1,
  StrutturaVanoDoccia = 2,
  Vetrate = 3,
  Generatore = 4,
  GeneratoreEstInt = 5,
  Optional = 6,
  PorteVetrate = 7,
  Panche = 8,
  Accessori = 9,
  Voltaggio = 10,
  Riepilogo = 11
}

export const ZINDEX_VANO_ESTERNO = 20

function Configuratore({ ...props }) {
  const store: TGeneralStore = useStore();
  const dispatch = useDispatch();

  const [width, height] = useWindowSize();

  const MAX_ALTEZZA_ALERT_CM_VANO_DOCCIA = 230;
  const MAX_ALTEZZA_ALERT_FT_VANO_DOCCIA = 8;
  const MIN_ALTEZZA_ALERT_CM_VANO_DOCCIA = 100;
  const MIN_LARGHEZZA_ALERT_CM_VANO_DOCCIA = 70;
  const MIN_PROFONDITA_ALERT_CM_VANO_DOCCIA = 70;

  const ZINDEX_MENU = 99


  //Auth
  const [errorAuth, setErrorAuth] = useState<String>("");
  //Menu
  const [menuIsOpen, setMenuIsOpen] = useState<boolean>(false);
  //Modal
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>({});
  //Wizard
  const [currentStep, setCurrentStep] = useState<number>(getLocalization() === Localization.AMERICA ? StepConfiguratore.VanoDoccia : StepConfiguratore.SceltaConfigurazione);

  const [isScrolledOnTop, setIsScrolledOnTop] = useState(true)

  const general: TGeneralStoreState = useSelector(
    (state: TGeneral) => state.general
  );

  const scelta_configurazione: TSceltaConfigurazioneStoreState = useSelector(
    (state: TGeneral) => state.scelta_configurazione
  );

  const MAX_WIDTH = 1440

  //Auth
  useEffect(() => {
    initConfiguratore()
  }, []);

  const initConfiguratore = async () => {
    const openLoginModal = () => {
      window.open(`${BASE_WP_URL}/oauth/authorize?response_type=code&client_id=${process.env.REACT_APP_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_AUTH_REDIRECT_URI}`, "_self");
    }
    if (getLocalization() !== Localization.AMERICA) {
      setErrorAuth(""); //reset error msg
      if (isLogged()) {
        console.log("is logged")
        setTimeout(() => { dispatch(actGeneral({ appLoading: false })) }, 1000)
      } else if (isTokenExpired()) {
        console.log("refresh token")
        try {
          const resp = await axios.post(`${BASE_WP_URL}/oauth/token`, {
            refresh_token: getRefreshToken()!,
            grant_type: "refresh_token",
            client_id: process.env.REACT_APP_AUTH_CLIENT_ID,
            client_secret: process.env.REACT_APP_AUTH_CLIENT_SECRET
          })
          const respUserToken: any = await axios(`${BASE_WP_URL}/oauth/me?access_token=${resp?.data?.access_token}`)
          const respUserInfo: any = await axios(`${API_URL}/v2/users/${respUserToken?.data?.ID}?context=edit`, {
            headers: {
              'Authorization': `Bearer ${resp?.data?.access_token}`
            }
          })
          const _roles = respUserInfo?.data?.extra_capabilities
          if (hasValidRoleToLogin(_roles)) {
            setAuthInfo(resp?.data, respUserToken?.data?.ID, _roles, respUserInfo?.data?.meta?.crm_language, respUserInfo?.data?.locale, respUserInfo?.data?.email)
            dispatch(actGeneral({ appLoading: false }))
          } else {
            setErrorAuth(props.t("v2.error.ruolo_non_ammesso"));
          }
        } catch (error) {
          openLoginModal()
        }
      } else {
        openLoginModal()
      }
    } else {
      dispatch(actGeneral({ appLoading: false }))
    }
  }

  const ImgWizard = (lastStep?: boolean) => {
    return (
      <ImgVano
        className={`${lastStep ? "" : "xs:h-auto lg:h-full"}`}
        isInternal={store.getState()?.scelta_configurazione?.type !== ConfigurationType.PORTE_VETRATE && store.getState()?.general?.internalExternalImage === EInternalExternalImage.INTERNAL}
        mainInternalImg={getImageOfCurrentStep(true)}
        layerInternalImgs={getImageLayersOfCurrentStep(true)}
        mainExternalImg={getImageOfCurrentStep(false)}
        layerExternalImgs={getImageLayersOfCurrentStep(false)}
        showToggle={showToggleInternalExternal()}
        onToggleChanged={(isChecked) => {
          dispatch(
            actGeneral({
              internalExternalImage: isChecked ? EInternalExternalImage.EXTERNAL : EInternalExternalImage.INTERNAL
            })
          )
        }}
      />
    )
  }

  const getSteps = (): TMenuItem[] => {
    let steps: TMenuItem[] = [
      {
        position: StepConfiguratore.SceltaConfigurazione,
        component: <SceltaConfigurazione />,
        label: props.t("menu.scelta_configurazione"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.VanoDoccia,
        component: <VanoDoccia />,
        label: props.t("menu.dimensioni"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.StrutturaVanoDoccia,
        component: <StrutturaVanoDoccia />,
        label: props.t("menu.rivestimenti"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Vetrate,
        component: <Vetrate />,
        label: props.t("menu.num_vetrate"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Generatore,
        component: <Generatore />,
        label: props.t("menu.generatore"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.GeneratoreEstInt,
        component: <GeneratoreEstInt />,
        label: props.t("menu.int_est"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Optional,
        component: <Optional />,
        label: props.t("menu.optional"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.PorteVetrate,
        component: <PorteVetrate />,
        label: props.t("menu.porta_vetrate"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Panche,
        component: <Panche />,
        label: props.t("menu.panche"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Accessori,
        component: <Accessori />,
        label: props.t("menu.accessori"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Voltaggio,
        component: <Voltaggio />,
        label: props.t("menu.voltaggio"),
        hideInMenu: false
      },
      {
        position: StepConfiguratore.Riepilogo,
        component: null,
        label: props.t("menu.riepilogo"),
        hideInMenu: false
      }
    ];

    if (scelta_configurazione.type === ConfigurationType.PORTE_VETRATE) {
      steps = steps.filter((s: TMenuItem) =>
        s.position !== StepConfiguratore.VanoDoccia &&
        s.position !== StepConfiguratore.StrutturaVanoDoccia &&
        s.position !== StepConfiguratore.Generatore &&
        s.position !== StepConfiguratore.GeneratoreEstInt &&
        s.position !== StepConfiguratore.Optional &&
        s.position !== StepConfiguratore.Panche &&
        s.position !== StepConfiguratore.Accessori &&
        s.position !== StepConfiguratore.Voltaggio
      )
    }

    if (getLocalization() === Localization.EUROPA) {
      steps = steps.filter((s: TMenuItem) => s.position !== StepConfiguratore.Voltaggio)
    } else {
      steps = steps.filter((s: TMenuItem) =>
        s.position !== StepConfiguratore.SceltaConfigurazione &&
        s.position !== StepConfiguratore.Vetrate &&
        s.position !== StepConfiguratore.Panche &&
        s.position !== StepConfiguratore.PorteVetrate &&
        s.position !== StepConfiguratore.Accessori)
    }


    if (Array.isArray(store?.getState()?.generatore_product?.list_generatori_interni) && store?.getState()?.generatore_product?.list_generatori_interni?.length) {
      let _volumeVano = getVolume(store?.getState().vano_doccia)
      // console.log(`[Volume Vano] ${JSON.stringify(_volumeVano)} - KIT ${store.getState()?.struttura_vano_doccia.kit_coibentazione}`)
      const showInternalGenerator = store?.getState()?.generatore_product?.list_generatori_interni?.some(g => {
        const _show = _volumeVano <= (getLocalization() === Localization.EUROPA || store.getState()?.struttura_vano_doccia.kit_coibentazione ? Number(g.acf?.max_volume_generatore_con_kit) : Number(g.acf?.max_volume_generatore_no_kit))
        return _show
      })
      if (!showInternalGenerator) {
        if (store?.getState()?.generatore?.tipologia !== EGeneratoreType.Esterno) {
          console.log(`[!] Disable step choice int/ext`)
          dispatch(actGeneratoreSetData({
            tipologia: EGeneratoreType.Esterno,
          }))
        }

        steps = steps.filter((s: TMenuItem) => s.position !== StepConfiguratore.Generatore)
      }
    }


    return steps
      .sort((a: TMenuItem, b: TMenuItem) => {
        return a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      }
      )
  }


  const openModal = (title: string, content: any, size: any, actionOk: any, actionCancel: any, modalType: IModalType) => {
    setModalData({ title, content, size, actionOk, actionCancel, modalType });
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalData({});
    setModalIsOpen(false);
  };

  const getImage = (image: any, step: StepConfiguratore): string => {
    const img = window.screen.width <= WIDTH_DESKTOP ? image["MOBILE"] : image["DESKTOP"]
    return typeof img === 'string' || img instanceof String ? img : img[currentStep !== step ? "DEFAULT" : "ACTIVE"]
  }

  const showToggleInternalExternal = (): boolean => {
    if (store?.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) return false
    return (getLocalization() !== Localization.AMERICA && currentStep >= StepConfiguratore.PorteVetrate) ||
      (getLocalization() === Localization.AMERICA && currentStep >= StepConfiguratore.Accessori)
  }

  const isImgInternalView = () => {
    const isInternalView = store.getState()?.general?.internalExternalImage === EInternalExternalImage.INTERNAL;
    return isInternalView && showToggleInternalExternal()
  }

  // useEffect(()=>{},[])

  const getImageOfCurrentStep = (isInternalView: boolean): string => {
    if (store?.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) {
      return getImage(IMAGES_VANO.STEP_1.VANO_INTERNO.ESTERNO, StepConfiguratore.StrutturaVanoDoccia)
    }
    if (currentStep === StepConfiguratore.VanoDoccia) {
      // Step 1
      return getImage(IMAGES_VANO.STEP_1.DIMENSIONI, StepConfiguratore.VanoDoccia)
    } else if (currentStep === StepConfiguratore.StrutturaVanoDoccia || (!store.getState()?.vetrate?.quantita && currentStep === StepConfiguratore.Vetrate)
      || (getLocalization() === Localization.AMERICA && currentStep >= StepConfiguratore.StrutturaVanoDoccia && currentStep < StepConfiguratore.Accessori)) {
      //Step 2 
      if (getLocalization() === Localization.AMERICA) {
        if (store.getState()?.struttura_vano_doccia?.kit_coibentazione) {
          if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
            return getImage(IMAGES_VANO.STEP_2.KIT_COIBENTAZIONE.MARMO, StepConfiguratore.StrutturaVanoDoccia)
          } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
            return getImage(IMAGES_VANO.STEP_2.KIT_COIBENTAZIONE.MOSAICO, StepConfiguratore.StrutturaVanoDoccia)
          } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
            return getImage(IMAGES_VANO.STEP_2.KIT_COIBENTAZIONE.PIETRA, StepConfiguratore.StrutturaVanoDoccia)
          }
        }
      }
      // Rivestimento before struttura  
      if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
        return getImage(IMAGES_VANO.STEP_2.RIVESTIMENTO.MARMO, StepConfiguratore.StrutturaVanoDoccia)
      } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
        return getImage(IMAGES_VANO.STEP_2.RIVESTIMENTO.MOSAICO, StepConfiguratore.StrutturaVanoDoccia)
      } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
        return getImage(IMAGES_VANO.STEP_2.RIVESTIMENTO.PIETRA, StepConfiguratore.StrutturaVanoDoccia)
      }
      // Struttura
      if (store.getState()?.struttura_vano_doccia?.struttura === EStrutturaVanoDoccia.Cartongesso) {
        return getImage(IMAGES_VANO.STEP_2.STRUTTURA.CARTONGESSO, StepConfiguratore.StrutturaVanoDoccia)
      } else if (store.getState()?.struttura_vano_doccia?.struttura === EStrutturaVanoDoccia.Muratura) {
        return getImage(IMAGES_VANO.STEP_2.STRUTTURA.MURATURA, StepConfiguratore.StrutturaVanoDoccia)
      } else if (store.getState()?.struttura_vano_doccia?.struttura === EStrutturaVanoDoccia.Poliestirene) {
        return getImage(IMAGES_VANO.STEP_2.STRUTTURA.POLIESTIRENE, StepConfiguratore.StrutturaVanoDoccia)
      }
    } else if (currentStep < StepConfiguratore.PorteVetrate) {
      //From Step 3
      if (getLocalization() === Localization.AMERICA) {
        if (!store.getState()?.struttura_vano_doccia?.kit_coibentazione) {
          if (store.getState()?.vetrate?.quantita === 1) {
            if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
              return getImage(IMAGES_VANO.STEP_3.VETRATA_1.NO_KIT_COIBENTAZIONE.MARMO, StepConfiguratore.Vetrate)
            } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
              return getImage(IMAGES_VANO.STEP_3.VETRATA_1.NO_KIT_COIBENTAZIONE.MOSAICO, StepConfiguratore.Vetrate)
            } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
              return getImage(IMAGES_VANO.STEP_3.VETRATA_1.NO_KIT_COIBENTAZIONE.PIETRA, StepConfiguratore.Vetrate)
            }
          } else if (store.getState()?.vetrate?.quantita === 2) {
            if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
              return getImage(IMAGES_VANO.STEP_3.VETRATA_2.NO_KIT_COIBENTAZIONE.MARMO, StepConfiguratore.Vetrate)
            } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
              return getImage(IMAGES_VANO.STEP_3.VETRATA_2.NO_KIT_COIBENTAZIONE.MOSAICO, StepConfiguratore.Vetrate)
            } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
              return getImage(IMAGES_VANO.STEP_3.VETRATA_2.NO_KIT_COIBENTAZIONE.PIETRA, StepConfiguratore.Vetrate)
            }
          }
        }
      }

      if (store.getState()?.vetrate?.quantita === 1) {
        if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
          return getImage(IMAGES_VANO.STEP_3.VETRATA_1.KIT_COIBENTAZIONE.MARMO, StepConfiguratore.Vetrate)
        } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
          return getImage(IMAGES_VANO.STEP_3.VETRATA_1.KIT_COIBENTAZIONE.MOSAICO, StepConfiguratore.Vetrate)
        } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
          return getImage(IMAGES_VANO.STEP_3.VETRATA_1.KIT_COIBENTAZIONE.PIETRA, StepConfiguratore.Vetrate)
        }
      } else if (store.getState()?.vetrate?.quantita === 2) {
        if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
          return getImage(IMAGES_VANO.STEP_3.VETRATA_2.KIT_COIBENTAZIONE.MARMO, StepConfiguratore.Vetrate)
        } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
          return getImage(IMAGES_VANO.STEP_3.VETRATA_2.KIT_COIBENTAZIONE.MOSAICO, StepConfiguratore.Vetrate)
        } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
          return getImage(IMAGES_VANO.STEP_3.VETRATA_2.KIT_COIBENTAZIONE.PIETRA, StepConfiguratore.Vetrate)
        }
      }
    } else {
      if (!isInternalView) {
        return getImage(IMAGES_VANO.STEP_1.VANO_INTERNO.ESTERNO, StepConfiguratore.StrutturaVanoDoccia)
      } else {
        if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Marmo) {
          return getImage(IMAGES_VANO.STEP_1.VANO_INTERNO.INTERNO_MARMO, StepConfiguratore.StrutturaVanoDoccia)
        } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Ceramica) {
          return getImage(IMAGES_VANO.STEP_1.VANO_INTERNO.INTERNO_MOSAICO, StepConfiguratore.StrutturaVanoDoccia)
        } else if (store.getState()?.struttura_vano_doccia?.rivestimento === ERivestimentoVanoDoccia.Pietra) {
          return getImage(IMAGES_VANO.STEP_1.VANO_INTERNO.INTERNO_STONE, StepConfiguratore.StrutturaVanoDoccia)
        }
      }
    }
    return getImage(IMAGES_VANO.STEP_1.DIMENSIONI, StepConfiguratore.VanoDoccia)
  }

  const getImageLayersOfCurrentStep = (isInternalView: boolean): ImgType[] => {

    let listImages: ImgType[] = []
    //Only if step generator is selected or is current step 

    //STEP MODELLO GENERATORE
    const slug_model_generatore = store.getState()?.generatore_product?.generatore_product?.slug
    if (slug_model_generatore === undefined || currentStep === StepConfiguratore.Generatore) {
      //STEP INT/EXT
      if (currentStep >= StepConfiguratore.Generatore) {
        const showIntExtLayer = (!getSteps().filter(s => s.position === StepConfiguratore.Generatore).length && !!store.getState()?.vetrate?.quantita) || (getSteps().filter(s => s.position === StepConfiguratore.Generatore).length)
        if (store.getState()?.generatore?.tipologia === EGeneratoreType.Interno && showIntExtLayer) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE.GENERATORE_INTERNO, StepConfiguratore.Generatore) })
        } else if (!isInternalView && store.getState()?.generatore?.tipologia === EGeneratoreType.Esterno && showIntExtLayer) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE.GENERATORE_ESTERNO, StepConfiguratore.Generatore) })
        }
      }
    }

    if (currentStep >= StepConfiguratore.GeneratoreEstInt) {
      if (slug_model_generatore === "touchsteam") {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.TOUCH_STEAM, StepConfiguratore.GeneratoreEstInt) })
      } else if (slug_model_generatore === "easysteam-smart") {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.EASYSTEAM, StepConfiguratore.GeneratoreEstInt) })
      } else if (slug_model_generatore === "aquasteam-smart") {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.AQUASTEAM, StepConfiguratore.GeneratoreEstInt) })
      } else if (slug_model_generatore === "nuvola") {
        if (!showToggleInternalExternal() || !isInternalView)
          listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.NUVOLA_EXT, StepConfiguratore.GeneratoreEstInt), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.NUVOLA_INT, StepConfiguratore.GeneratoreEstInt) })
      } else if (slug_model_generatore === "nuvola-smart") {
        if (!showToggleInternalExternal() || !isInternalView)
          listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.NUVOLA_SP_EXT, StepConfiguratore.GeneratoreEstInt), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.NUVOLA_SP_INT, StepConfiguratore.GeneratoreEstInt) })
      } else if (slug_model_generatore === "inside") {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_GENERATORE_MODELLO.INSIDE, StepConfiguratore.GeneratoreEstInt) })
      }
    }


    //STEP OPTIONAL
    if (currentStep >= StepConfiguratore.Optional) {
      if (store.getState()?.optional?.optionals?.filter(o => o?.optional?.slug === "me-99-00-0421-interfaccia-domotica").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_OPTIONAL.DOMOTICA, StepConfiguratore.Optional) })
      }
      if ((!showToggleInternalExternal() || isInternalView) && store.getState()?.optional?.optionals?.filter(o => o?.optional?.slug === "ha-70-00-0009-plafoniera-di-illuminazione-bianca").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_OPTIONAL.PLAFONIERA_BIANCA, StepConfiguratore.Optional) })
      }
      if ((!showToggleInternalExternal() || isInternalView) && store.getState()?.optional?.optionals?.filter(o => o?.optional?.slug === "ha-70-10-0005-plafoniera-di-illuminazione-concromoterapia-luce-bianca-e-altoparlanti-passivi").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_OPTIONAL.PLAFONIERA_CROMO_BIANCA, StepConfiguratore.Optional) })
      }
      if (store.getState()?.optional?.optionals?.filter(o => o?.optional?.slug === "ha-70-10-0008-ess-effe-sound-system").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_OPTIONAL.SOUND_SYSTEM, StepConfiguratore.Optional) })
      }
      if ((!showToggleInternalExternal() || !isInternalView) &&
        (store.getState()?.optional?.optionals?.filter(o => o?.optional?.slug === "me-60-00-0049-pannello-touch-con-sonda-remotata-per-nuvola-smart-power" ||
          o?.optional?.slug === "me-60-00-0050-pannello-touch-con-sonda-remotata-per-nuvola").length)) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_OPTIONAL.PANNELLO_ESTERNO_SONDA, StepConfiguratore.Optional), zIndex: ZINDEX_VANO_ESTERNO + 1 })
      }
      if ((!showToggleInternalExternal() || !isInternalView) &&
        (store.getState()?.optional?.optionals?.filter(o => o?.optional?.slug === "me-60-00-0042-pannello-touch-esterno-per-nuvola-smart-power" ||
          o?.optional?.slug === "me-60-00-0033-pannello-touch-esterno" ||
          o?.optional?.slug === "me-60-00-0046-pannello-touch-esterno-per-nuvola").length)) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_OPTIONAL.PANNELLO_ESTERNO, StepConfiguratore.Optional), zIndex: ZINDEX_VANO_ESTERNO + 1 })
      }
    }

    //STEP PORTE VETRATE
    if (currentStep >= StepConfiguratore.PorteVetrate) {
      if (!isInternalView) {
        const slug_porta = store.getState()?.porta_vetrata?.modello_porta?.slug
        const slug_vetrata = store.getState()?.porta_vetrata?.modello_vetrata?.slug
        if (slug_porta === "fit-65" || slug_porta === "fit-80") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.FIT_65_80, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        // -- STANDARD
        if (slug_porta === "tuttovetro-inside-70") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_70, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "tuttovetro-inside-120") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_120, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "tuttovetro-inside-170") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_170, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "fit-120") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.FIT_120, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_vetrata === "vetrata-fit-50") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.FIT_V50, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "spazioslide-130" || slug_porta === "spazioslide-160") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIOSLIDE_130_160, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "spaziofilo-80" || slug_porta === "spaziofilo-100") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIOFILO_80_100, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "spaziofilo-120" || slug_porta === "spaziofilo-160") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIOFILO_120_160, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_vetrata === "vetrata-spaziofilo-40" || slug_vetrata === "vetrata-spaziofilo-80") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIOFILO_V, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "spazio-80") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIO_80, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "spazio-120" || slug_porta === "spazio-160") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIO_120_160, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_vetrata === "vetrata-spazio-40" || slug_vetrata === "vetrata-spazio-80") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.SPAZIO_V, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        // -- CUSTOM
        if (slug_porta === "porta-tuttovetro-contour") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_CONTOUR_PORTA, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "vetro-porta-tuttovetro-contour") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_CONTOUR_VETRO_PORTA, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "vetro-porta-vetro-tuttovetro-contour") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_CONTOUR_VETRO_PORTA_VETRO, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "angolo-vetro-porta-tuttovetro-contour") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_CONTOUR_D, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_vetrata === "vetrata-tuttovetro-contour") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_CONTOUR_V, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }

        if (slug_porta === "porta-tuttovetro-inside") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_INSIDE_A, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "vetro-porta-tuttovetro-inside") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_INSIDE_B, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "vetro-porta-vetro-tuttovetro-inside") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_INSIDE_C, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_porta === "porta-ad-angolo-tuttovetro-inside") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_INSIDE_D, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
        if (slug_vetrata === "vetrata-tuttovetro-inside") {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PORTE.TUTTOVETRO_INSIDE_V, StepConfiguratore.PorteVetrate), zIndex: ZINDEX_VANO_ESTERNO + 1 })
        }
      }
    }


    // STEP PANCHE
    if (currentStep >= StepConfiguratore.Panche) {
      const panche = store.getState()?.panche?.panche || []
      const panca_custom = store.getState()?.panche?.panca_custom
      if (panche.filter(p => p.panca?.slug === "panca-logica-69").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.LOGICA_69, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-logica-79").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.LOGICA_79, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-logica-114").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.LOGICA_114, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-topkapi-75").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.TOPKAPI_75, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-topkapi-115").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.TOPKAPI_115, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-ad-angolo-linea-alu").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ALU_ANGOLO, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-3-moduli-linea-alu").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ALU_3, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-2-moduli-linea-alu").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ALU_2, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "panca-1-modulo-linea-alu").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ALU_1, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "sedile-mini").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.SEDILE_MINI, StepConfiguratore.Panche) })
      }
      if (panche.filter(p => p.panca?.slug === "fonte-mini").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.FONTE_MINI, StepConfiguratore.Panche) })
      }

      // -- Custom
      const pieces_custom_panca = panca_custom?.configurations?.length || 0
      if (panca_custom?.modello?.slug === SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE) {
        const base_pieces = panca_custom?.configurations?.find(c => c?.panca_custom?.id === SLUG_PANCA_CONFIGURATION_RETTANGOLARE_BASE)?.quantity || 0
        const angle_pieces = panca_custom?.configurations?.find(c => c?.panca_custom?.id === SLUG_PANCA_CONFIGURATION_RETTANGOLARE_ANGOLO)?.quantity || 0
        if (base_pieces === 1 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_1_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 2 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_2_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 2 && angle_pieces === 1) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_2_x_1, StepConfiguratore.Panche) })
        } else if (base_pieces === 3 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_3_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 3 && angle_pieces === 2) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_3_x_2, StepConfiguratore.Panche) })
        }
      } else if (panca_custom?.modello?.slug === SLUG_PANCA_CUSTOM_SEZ_ARROTONDATA_LED) {
        const base_pieces = panca_custom?.configurations?.find(c => c?.panca_custom?.id === SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_BASE)?.quantity || 0
        const angle_pieces = panca_custom?.configurations?.find(c => c?.panca_custom?.id === SLUG_PANCA_CONFIGURATION_ARROTONDATA_LED_ANGOLO)?.quantity || 0
        if (base_pieces === 1 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ARROTONDATA_LED_1_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 2 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ARROTONDATA_LED_2_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 2 && angle_pieces === 1) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ARROTONDATA_LED_2_x_1, StepConfiguratore.Panche) })
        } else if (base_pieces === 3 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ARROTONDATA_LED_3_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 3 && angle_pieces === 2) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.ARROTONDATA_LED_3_x_2, StepConfiguratore.Panche) })
        }
      } else if (panca_custom?.modello?.slug === SLUG_PANCA_CUSTOM_SEZ_RETTANGOLARE_LED) {
        const base_pieces = panca_custom?.configurations?.find(c => c?.panca_custom?.id === SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_BASE)?.quantity || 0
        const angle_pieces = panca_custom?.configurations?.find(c => c?.panca_custom?.id === SLUG_PANCA_CONFIGURATION_RETTANGOLARE_LED_ANGOLO)?.quantity || 0
        if (base_pieces === 1 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_LED_1_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 2 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_LED_2_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 2 && angle_pieces === 1) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_LED_2_x_1, StepConfiguratore.Panche) })
        } else if (base_pieces === 3 && angle_pieces === 0) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_LED_3_x_0, StepConfiguratore.Panche) })
        } else if (base_pieces === 3 && angle_pieces === 2) {
          listImages.push({ src: getImage(IMAGES_VANO.STEP_PANCHE.RETTANGOLARE_LED_3_x_2, StepConfiguratore.Panche) })
        }
      }
    }

    // STEP ACCESSORI
    if (currentStep >= StepConfiguratore.Accessori) {
      const accessori = store.getState()?.accessori?.accessori || []
      if (accessori.filter(a => a.accessorio?.slug === "ciotola-per-fonte").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_ACCESSORI.CIOTOLA, StepConfiguratore.Accessori) })
      }
      if (accessori.filter(a => a.accessorio?.slug === "tubo-kneipp").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_ACCESSORI.TUBO_KNEIP, StepConfiguratore.Accessori) })
      }
      if (accessori.filter(a => a.accessorio?.slug === "pp50000104-fit-shower").length) {
        listImages.push({ src: getImage(IMAGES_VANO.STEP_ACCESSORI.FIT_SHOWER, StepConfiguratore.Accessori) })
      }
    }

    return listImages
  }

  const scrollToTopRiepilogo = () => {
    document.getElementById("container-configuratore")?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }
  const scrollToTopWizard = () => {
    document.getElementById("body-wizard")?.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }




  const getCurrentStepIndex = () => {
    const steps = getSteps()
    return steps.map((it: TMenuItem) => it.position).indexOf(currentStep)
  }
  const getNextStep = () => {
    const steps = getSteps()
    const indexCurrentStepInSteps = getCurrentStepIndex()
    return indexCurrentStepInSteps < steps.length - 1 ? steps[indexCurrentStepInSteps + 1].position : undefined
  }
  const getPrevStep = () => {
    const steps = getSteps()
    const indexCurrentStepInSteps = getCurrentStepIndex()
    return indexCurrentStepInSteps > 0 ? steps[indexCurrentStepInSteps - 1].position : undefined
  }

  const goForward = (skip_check?: boolean) => {
    if (!skip_check) {
      const stepError = getError()
      if (!!stepError) {
        console.log(`errors: `, stepError)
        dispatch(actGeneral({ stepError }))
        return;
      }
    }

    dispatch(actGeneral({ stepError: undefined }))

    if (!skip_check && currentStep === StepConfiguratore.VanoDoccia) {
      if (
        ((store.getState().vano_doccia?.altezza || 0) > MAX_ALTEZZA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI) ||
        getUnitOfMeasure() === UnitOfMeasure.PIEDI && ((store.getState().vano_doccia?.altezza! > MAX_ALTEZZA_ALERT_FT_VANO_DOCCIA) ||
          (store.getState().vano_doccia?.altezza! === MAX_ALTEZZA_ALERT_FT_VANO_DOCCIA && store.getState().vano_doccia?.altezza_inc! > 0))
      ) {
        openModal(props.t('general.attenzione'), () => {
          return (
            <Text title={props.t(getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI ? 'vanodoccia.alert.over_230' : 'vanodoccia.alert.over_230cm_to_feet')} className="text-center" />
          )
        }, ModalSize.lg, () => {
          return (<ActionButton importance={IActionButtonType.Secondary} title={props.t('general.prosegui')}
            onClick={() => {
              closeModal()
              goForward(true)
            }} />)
        }
          , null, IModalType.Warning)
        return
      }
    } else if (currentStep === StepConfiguratore.PorteVetrate) {
      dispatch(actGeneral({
        internalExternalImage: EInternalExternalImage.INTERNAL
      }))
    }
    else if (currentStep === StepConfiguratore.Panche) {
      dispatch(actGeneral({
        internalExternalImage: EInternalExternalImage.INTERNAL
      }))
    }

    const next_step = getNextStep()
    if (next_step === StepConfiguratore.Riepilogo) {
      // scrollToTopRiepilogo();
    } else {
      setIsScrolledOnTop(true)
      scrollToTopWizard();
    }



    next_step !== undefined && setCurrentStep(next_step);
  };



  const header = () => {
    const _isScrolledOnTop = isScrolledOnTop || !isLastStep
    return (
      <div className={` header-app relative flex items-center w-full ${(_isScrolledOnTop) ? "ontop py-4" : "onscroll py-3"}`}>
        <div className="relative flex items-center w-full lg:mx-auto" style={{ maxWidth: MAX_WIDTH }}>
          <button
            className="hamburger-menu focus:outline-none pt-2 absolute xs:right-6 md:left-6 "
            style={{ zIndex: ZINDEX_MENU }}
            onClick={() => {
              setMenuIsOpen(true);
            }}
          >
            <img src={_isScrolledOnTop ? ic_menu : ic_menu_white} />
          </button>
          <div className="flex justify-center items-center w-full">
            <img id="logo-wizard" src={_isScrolledOnTop ? logo : logo_white} className={`${_isScrolledOnTop ? "xs:w-28 md:w-32" : "xs:w-20 md:w-24"} object-contain`} />
            {getLocalization() === Localization.AMERICA && <img src={logo_us} className="w-36 object-contain ml-2" />}
          </div>
        </div>
      </div>
    )
  }

  const wizardHeader = () => {
    return (
      <div className="flex flex-row w-full pb-6 md:mt-36 xs:mt-0">
        {getSteps().map((s, indexS) => {
          const isActive = indexS === getCurrentStepIndex();
          const isDone = indexS <= getCurrentStepIndex();
          return (
            <div
              key={indexS}
              className={`w-full px-2 flex flex-col items-center ${isActive && "active"
                }`}
            >
              <hr
                style={{ height: isActive ? 2 : 1 }}
                className={`w-full border-0 px-1 ${isDone ? "bg-fluo-effe" : "bg-wizard-effe"
                  }`}
              />
              {indexS <= getCurrentStepIndex() && (
                <span className={`${isActive ? "text-fluo-effe" : "text-wizard-effe"} text-text`}>{indexS + 1}</span>
              )}
            </div>
          );
        })}
      </div>
    )
  }



  const getError = (): StepError | undefined => {
    if (currentStep === StepConfiguratore.SceltaConfigurazione) {
      if (!store.getState().scelta_configurazione?.type) {
        return { step: StepConfiguratore.SceltaConfigurazione, msg: props.t("error.seleziona_configurazione"), fields: [] }
      }
    }
    else if (currentStep === StepConfiguratore.VanoDoccia) {
      if (!store.getState().vano_doccia?.altezza || !store.getState().vano_doccia?.profondita || !store.getState().vano_doccia?.larghezza) {
        return {
          step: StepConfiguratore.VanoDoccia, msg: props.t("vanodoccia.alert.manca_campo"), fields:
            ([] as any).concat(!store.getState().vano_doccia?.larghezza ? [{ id: "larghezza", }] : [])
              .concat(!store.getState().vano_doccia?.profondita ? [{ id: "profondita", }] : [])
              .concat(!store.getState().vano_doccia?.altezza ? [{ id: "altezza", }] : [])
        }
      }
      else if (
        ((store.getState().vano_doccia?.larghezza || 0) < MIN_LARGHEZZA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI) ||
        ((store.getState().vano_doccia?.profondita || 0) < MIN_PROFONDITA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI) ||
        ((store.getState().vano_doccia?.altezza || 0) < MIN_ALTEZZA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI) ||
        (getCmFromFtAndInch(store.getState().vano_doccia?.larghezza, store.getState().vano_doccia?.larghezza_inc) < MIN_LARGHEZZA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.PIEDI) ||
        (getCmFromFtAndInch(store.getState().vano_doccia?.profondita, store.getState().vano_doccia?.profondita_inc) < MIN_PROFONDITA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.PIEDI) ||
        (getCmFromFtAndInch(store.getState().vano_doccia?.altezza, store.getState().vano_doccia?.altezza_inc) < MIN_ALTEZZA_ALERT_CM_VANO_DOCCIA && getUnitOfMeasure() === UnitOfMeasure.PIEDI)
      ) {
        return {
          step: StepConfiguratore.VanoDoccia, msg: props.t(getUnitOfMeasure() === UnitOfMeasure.CENTIMETRI ? 'vanodoccia.alert.dim_min_cm' : 'vanodoccia.alert.dim_min_ft'), fields:
            [{ id: "larghezza", msg: "" }, { id: "profondita", msg: "" }, { id: "altezza", msg: "" }]
        }
      }
    } else if (currentStep === StepConfiguratore.StrutturaVanoDoccia) {
      const missingFields = ([] as any).concat(
        !store.getState().struttura_vano_doccia?.rivestimento ? [{ id: "rivestimento", }] : [])
        .concat(getLocalization() === Localization.EUROPA && !store.getState().struttura_vano_doccia?.struttura ? [{ id: "struttura", }] : [])
        .concat(isKitCoibentazioneVisible() && store.getState().struttura_vano_doccia?.kit_coibentazione === undefined ? [{ id: "kit_coibentazione", }] : [])
        .concat(isKitCoibentazioneVisible() && store.getState().struttura_vano_doccia?.kit_coibentazione === true && getLocalization() === Localization.AMERICA &&
          store.getState().struttura_vano_doccia?.add_kit_coibentazione_to_quote === undefined ? [{ id: "add_kit_coibentazione_to_quote", }] : []);
      if (!!missingFields?.length) {
        return {
          step: StepConfiguratore.StrutturaVanoDoccia, msg: props.t("general.campi_mancanti"), fields: missingFields
        }
      }
    } else if (currentStep === StepConfiguratore.Vetrate) {
      if ((store.getState().vetrate?.quantita || 0) < 1) {
        return { step: StepConfiguratore.Vetrate, msg: props.t("general.campi_mancanti"), fields: [{ id: "vetrata", msg: "" }] }
      }
    } else if (currentStep === StepConfiguratore.PorteVetrate && store.getState()?.scelta_configurazione?.type === ConfigurationType.PORTE_VETRATE) {
      if (!store.getState()?.porta_vetrata?.porta_prodotto && !store.getState()?.porta_vetrata?.modello_porta) {
        return { step: StepConfiguratore.PorteVetrate, msg: "", fields: [{ id: "general", msg: props.t("error.seleziona_porta") }] }
      }
    } else if (currentStep === StepConfiguratore.Generatore) {
      if (!store.getState().generatore?.tipologia) {
        return { step: StepConfiguratore.Generatore, msg: props.t("general.campi_mancanti"), fields: [{ id: "tipologia", msg: "" }] }
      }
    } else if (currentStep === StepConfiguratore.GeneratoreEstInt) {
      if (!store.getState().generatore_product?.generatore_model) {
        return { step: StepConfiguratore.GeneratoreEstInt, msg: props.t("error.seleziona_generatore"), fields: [] }
      }
    }
    else if (currentStep === StepConfiguratore.Voltaggio) {
      if (!store.getState().voltaggio?.voltaggio) {
        return { step: StepConfiguratore.Voltaggio, msg: props.t("general.campi_mancanti"), fields: [{ id: "voltaggio", msg: "" }] }
      }
    }
    return undefined
  }

  const goBack = () => {
    const prev_step = getPrevStep()
    if (prev_step !== undefined) {
      setCurrentStep(prev_step);
      scrollToTopWizard();
    }
  };

  const styleImg = currentStep >= StepConfiguratore.PorteVetrate ? { zIndex: isImgInternalView() ? 0 : ZINDEX_VANO_ESTERNO } : {}


  const isLastStep = getCurrentStepIndex() === getSteps().length - 1
  return <div id="container-configuratore">

    <Loader loading={general?.appLoading} type={ELoader.PRIMARY} error={!!errorAuth} errorMsg={errorAuth} />

    <div
      className="h-screen overflow-hidden"
      tabIndex={0}
      onKeyDown={(event: any) => {
        var code = event.keyCode || event.which;
        if (code === 13 && !document.getElementById("modal-configuratore")) {
          goForward();
        }
      }}
    >

      <div className="flex h-full w-full" style={{ maxWidth: isLastStep ? "unset" : MAX_WIDTH, margin: "auto" }}> {/**container */}
        <div className="flex flex-col w-full h-full">
          {/* HEADER */}
          {header()}

          {/* BODY */}
          <div style={{ maxWidth: MAX_WIDTH, margin: "auto" }} className={`flex ${isLastStep ? "xs:flex-col lg:flex-row overflow-scroll" : "overflow-hidden"} h-full`}>
            {
              !isLastStep ?
                <>
                  <Col xs={12} lg={6} className="col-content">
                    <div className="flex h-full flex-1 flex-col">
                      <Wizard
                        steps={getSteps().map((s: any) => s.component)}
                        currentStepIndex={getCurrentStepIndex()}
                        onGoBack={() => goBack()}
                        onGoForward={() => goForward()}
                        imgVano={() =>
                          <div id="imgWizardInside" className="flex-1 w-full xs:block  max-w-xs mx-auto">
                            {ImgWizard()}
                          </div>}
                        hideFooter={currentStep === StepConfiguratore.Riepilogo}
                        wizardHeader={wizardHeader}
                      />
                    </div>
                  </Col>

                  <Col xs={12} lg={6}>
                    <div className="flex-1 xs:hidden md:block h-full">{ImgWizard()}</div>
                  </Col>
                </>
                :
                <Riepilogo header={wizardHeader} imgWizard={() => ImgWizard(true)} onGoToStep={(step: number) => {
                  setCurrentStep(step)
                }}
                  onScrollBody={(top: number) => {
                    if ((top === 0 && !isScrolledOnTop) || (top !== 0 && isScrolledOnTop)) {
                      setIsScrolledOnTop(top === 0)
                    }
                  }}
                  mainInternalImg={getImageOfCurrentStep(true)}
                  layerInternalImgs={getImageLayersOfCurrentStep(true)}
                  mainExternalImg={getImageOfCurrentStep(false)}
                  layerExternalImgs={getImageLayersOfCurrentStep(false)}
                />
            }
          </div>
        </div>


        <Menu
          menuIsOpen={menuIsOpen}
          onClose={() => {
            setMenuIsOpen(false);
          }}
          items={getSteps().filter((it: TMenuItem) => !it.hideInMenu).map((item, position) => {
            return ({ ...item, positionInMenu: position })
          })}
          onClickMenuItem={(item: TMenuItem) => {
            console.log("GO TO POSITION", item.position)
            setIsScrolledOnTop(true)
            setCurrentStep(item.position)
          }}
          currentStep={getCurrentStepIndex()}
          className="xs:w-full lg:w-5/6 overflow-hidden"
        />


      </div>

      <Modal
        title={modalData.title}
        size={modalData.size}
        onClose={() => closeModal()}
        visible={modalIsOpen}
        cancelAction={modalData.actionCancel && <div>{modalData.actionCancel()}</div>}
        okAction={<div>{modalData.actionOk && modalData.actionOk()}</div>}
        modalType={modalData.modalType}
      >
        {modalData.content && modalData.content()}
      </Modal>
    </div>

  </div>

}

export default connect((state: TGeneral, ownProps) => ({
  general: state.general,
  scelta_configurazione: state.scelta_configurazione,
  struttura_vano_doccia: state.struttura_vano_doccia,
  vetrate: state.vetrate,
  generatore: state.generatore,
  generatore_product: state.generatore_product,
  optional: state.optional,
  panche: state.panche,
  porta_vetrata: state.porta_vetrata,
  accessori: state.accessori
}), { actGeneral })(withTranslation()(Configuratore));
